// light ----------------------------------------------------------------------
.fsde-theme-light {
    color-scheme: light;
}

.fsde-theme-light body {
    background-color: $fsde-color-light-white;
    color: $fsde-color-light-black;

    // banner text
    header .fsde-desktop-header .fsde-banner-text a,
    header .fsde-desktop-header .fsde-banner-text a:hover,
    header .fsde-desktop-header .fsde-banner-text a:active,
    header .fsde-desktop-header .fsde-banner-text a:visited {
        color: $fsde-color-light-black;
    }

    // navigation
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:hover,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:active,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:visited {
        color: $fsde-color-light-grey;
    }

    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li.active a {
        color: $fsde-color-light-black;
    }

    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:hover,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:active,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:visited {
        color: $fsde-color-light-black;
    }

    // right
    header .fsde-desktop-header .fsde-right a,
    header .fsde-desktop-header .fsde-right a:hover,
    header .fsde-desktop-header .fsde-right a:active,
    header .fsde-desktop-header .fsde-right a:visited {
        color: $fsde-color-light-black;
    }

    // code
    code.shj-inline {
        color: $fsde-color-light-black !important;
        background-color: $fsde-color-light-light-grey !important;
    }

    // footer
    footer.fsde-footer i {
        color: $fsde-color-light-grey;
    }
}

// dark -----------------------------------------------------------------------
.fsde-theme-dark {
    color-scheme: dark;
}

.fsde-theme-dark body {
    background-color: $fsde-color-dark-white;
    color: $fsde-color-dark-black;

    // banner text
    header .fsde-desktop-header .fsde-banner-text a,
    header .fsde-desktop-header .fsde-banner-text a:hover,
    header .fsde-desktop-header .fsde-banner-text a:active,
    header .fsde-desktop-header .fsde-banner-text a:visited {
        color: $fsde-color-dark-black;
    }

    // navigation
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:hover,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:active,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:visited {
        color: $fsde-color-dark-grey;
    }

    header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li.active a {
        color: $fsde-color-dark-black;
    }

    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:hover,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:active,
    header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:visited {
        color: $fsde-color-dark-black;
    }

    // right
    header .fsde-desktop-header .fsde-right a,
    header .fsde-desktop-header .fsde-right a:hover,
    header .fsde-desktop-header .fsde-right a:active,
    header .fsde-desktop-header .fsde-right a:visited {
        color: $fsde-color-dark-black;
    }

    // code
    code.shj-inline {
        color: $fsde-color-dark-black !important;
        background-color: $fsde-color-dark-light-grey !important;
    }

    // footer
    footer.fsde-footer i {
        color: $fsde-color-dark-grey;
    }
}
