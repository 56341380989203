$avatar-width: 100px;
$avatar-height: 100px;

@include media-breakpoint-down(md) {
    header .fsde-desktop-header {
        display: none !important;
    }
}

header .fsde-desktop-header {
    display: grid;

    grid-template-columns:
        calc(#{$avatar-width} + #{$fsde-grid-gutter})
        auto
        max-content;

    grid-template-rows: $avatar-height;
}

// avatar
header .fsde-desktop-header .fsde-avatar {
    width: $avatar-width;
    height: $avatar-height;

    // grid
    grid-column: 1;
    grid-row: 1;
}

header .fsde-desktop-header .fsde-avatar img {
    width: $avatar-width;
    height: $avatar-height;
    border-radius: 9999px;
}

// banner
header .fsde-desktop-header .fsde-banner {

    // grid
    grid-column: 2;
    grid-row: 1;
}

// banner text
header .fsde-desktop-header .fsde-banner-text a,
header .fsde-desktop-header .fsde-banner-text a:hover,
header .fsde-desktop-header .fsde-banner-text a:active,
header .fsde-desktop-header .fsde-banner-text a:visited {
    font-weight: 900;
    font-size: 40px;
    line-height: 35px;
    text-decoration: none;
}

// navigation -----------------------------------------------------------------
header .fsde-desktop-header .fsde-banner nav ul,
header .fsde-desktop-header .fsde-banner nav li {
    list-style: none;
}

header .fsde-desktop-header .fsde-banner nav ul {
    margin: 0;
    padding: 0;
}

header .fsde-desktop-header .fsde-banner nav li {
    display: inline;
}

// navigation
header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a,
header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:hover,
header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:active,
header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li a:visited {
    text-decoration: none;
    margin-right: $fsde-grid-gutter-half;
    font-size: 15px;
}

header .fsde-desktop-header .fsde-banner nav ul.fsde-navigation li.active a {
    font-weight: bolder;
}

// social media links
header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links {
    margin-top: 5px;
}

header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a,
header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:hover,
header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:active,
header .fsde-desktop-header .fsde-banner nav ul.fsde-social-media-links li a:visited {
    text-decoration: none;
    margin-right: 5px;
}

// right ----------------------------------------------------------------------
header .fsde-desktop-header .fsde-right {

    // grid
    grid-column: 3;
    grid-row: 1;

    // styling
    text-align: right;
}

header .fsde-header .fsde-right a,
header .fsde-header .fsde-right a:hover,
header .fsde-header .fsde-right a:active,
header .fsde-header .fsde-right a:visited {
    text-decoration: none;
}
