// wrapper --------------------------------------------------------------------
div.fsde-wrapper {
    max-width: calc(#{$fsde-page-width} + (#{$fsde-grid-gutter} * 2));
    margin: 0 auto;
    padding: $fsde-grid-gutter;
}

@include media-breakpoint-down(md) {
    div.fsde-wrapper {
        padding: $fsde-grid-gutter-half;
    }
}

// theme toggle ---------------------------------------------------------------
.fsde-theme-toggle {
    cursor: pointer;
}

.fsde-theme-dark .fsde-theme-toggle {
    transform: scaleX(-1);
}

// main -----------------------------------------------------------------------
main.fsde-main {
    min-height: 80vh;
    margin-top: $fsde-grid-gutter;
}

// footer ---------------------------------------------------------------------
footer.fsde-footer {
    margin-top: $fsde-grid-gutter;
}

footer.fsde-footer .fsde-footer-divider {
    text-align: center;
    font-size: 5px;
}

footer.fsde-footer .fsde-footer-right {
    margin-top: $fsde-grid-gutter-half;
    text-align: right;
    color: $fsde-color-light-grey;
    font-size: 70%;
}
