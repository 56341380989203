body {
    font-family: $fsde-font-family;
    font-size: $fsde-font-size;
}

._fsde-link-after {
    font-family: $fsde-icon-font-family;
    content: "\f0c1";
    padding-left: 0.2em;
    font-size: 70%;
}

// headings -------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fsde-font-family;
    margin-top: $fsde-grid-gutter;
    margin-bottom: $fsde-grid-gutter-half;
}

h1 {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 0;
}

h2 {
    font-weight: bold;
    font-size: 22px;
    margin-top: 1.5em;
}

h3 {
    font-weight: bolder;
    font-size: 20px;
    margin-top: 1.5em;
}

h1.underlined,
h2.underlined,
h3.underlined,
h4.underlined,
h5.underlined,
h6.underlined {
    width: 100%;
    border-bottom: 1px solid;
}

// meta data ------------------------------------------------------------------
.meta-data {
    margin-bottom: $fsde-grid-gutter-half;
}

.meta-data,
.meta-data * {
    line-height: 1.5em;
}

.meta-data > * {
    margin-right: 0.25em;
}

.fsde-theme-light {
    .meta-data {
        color: $fsde-color-light-grey;
    }
}

.fsde-theme-dark {
    .meta-data {
        color: $fsde-color-dark-grey;
    }
}

.meta-data i {
    font-size: 15px;
}

.meta-data a,
.meta-data a:hover,
.meta-data a:active,
.meta-data a:visited {
//    margin-right: 0.25em;
    text-decoration: none;
    color: inherit;
    white-space: nowrap;
}

// heading anchors ------------------------------------------------------------
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    text-decoration: none;
}

h1 a::after,
h2 a::after,
h3 a::after,
h4 a::after,
h5 a::after,
h6 a::after {
    @extend ._fsde-link-after;
}

.fsde-theme-light {
    h1 a::after,
    h2 a::after,
    h3 a::after,
    h4 a::after,
    h5 a::after,
    h6 a::after {
        color: $fsde-color-light-grey;
    }
}

.fsde-theme-dark {
    h1 a::after,
    h2 a::after,
    h3 a::after,
    h4 a::after,
    h5 a::after,
    h6 a::after {
        color: $fsde-color-dark-grey;
    }
}

// table of contents ----------------------------------------------------------
ul.fsde-toc {
    list-style: none;
    padding-left: $fsde-grid-gutter-half !important;
    margin-bottom: $fsde-grid-gutter-half;
    margin-top: -10px;
}

ul.fsde-toc a {
    @extend .link;

    text-decoration: none;
    font-weight: 600;
}

ul.fsde-toc a::after {
    content: "";
}

// links ----------------------------------------------------------------------
a.link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
}

a.link span {
    text-decoration: underline;
}

a.link::after {
    @extend ._fsde-link-after;
}

.fsde-theme-light {
    a.link,
    a.link:hover,
    a.link:active,
    a.link:visited {
        color: $fsde-color-light-black;
    }

    a.link::after {
        color: $fsde-color-light-grey;
    }
}

.fsde-theme-dark {
    a.link,
    a.link:hover,
    a.link:active,
    a.link:visited {
        color: $fsde-color-dark-black;
    }

    a.link::after {
        color: $fsde-color-dark-grey;
    }
}

// paragraphs -----------------------------------------------------------------
p {
    text-align: justify;
    text-align-last: left;
    margin-bottom: $fsde-grid-gutter-half;
}

// pre ------------------------------------------------------------------------
pre {
    font-family: $fsde-monospace-font-family;
    margin-top: 0;
    margin-bottom: $fsde-grid-gutter-half;
}

// blockquote -----------------------------------------------------------------
blockquote {
    padding-left: $fsde-grid-gutter-half;
    margin-bottom: $fsde-grid-gutter-half;
    font-style: italic;
    font-weight: 600;
}

blockquote span.source {
    display: block;
    margin-top: $fsde-grid-gutter;
    text-align: right;
    font-style: normal;
    font-size: 75%;
}

.fsde-theme-light {
    blockquote {
        border-left: 4px solid $fsde-color-light-black;
    }
}

.fsde-theme-dark {
    blockquote {
        border-left: 4px solid $fsde-color-dark-black;
    }
}

// media ----------------------------------------------------------------------
iframe {
    width: 100%;
    margin-top: $fsde-grid-gutter-half;
    margin-bottom: $fsde-grid-gutter;
}

main.fsde-main img.image {
    max-width: 100%;
    display: block;
    margin-top: $fsde-grid-gutter;
    margin-bottom: $fsde-grid-gutter;
    margin-left: auto;
    margin-right: auto;
}

span.caption {
    display: block;
    font-size: 75%;
    margin-top: -$fsde-grid-gutter-half;
}

span.code {
    font-family: $fsde-monospace-font-family;
    font-size: 90%;
    font-weight: 500;

    display: inline-flex;
    padding: 0 0.25em;
    justify-content: center;

    border-radius: 3px;
}

// lists ----------------------------------------------------------------------
main.fsde-main ul {
    padding-left: $fsde-grid-gutter;
    margin-bottom: $fsde-grid-gutter-half;
}

// tables ---------------------------------------------------------------------
main.fsde-main table {
    width: 100%;
    border: 0;
}

main.fsde-main table th,
main.fsde-main table td {
    vertical-align: top;
}

// tables ---------------------------------------------------------------------
main.fsde-main table.attribute-table th {
    min-width: 30%;
    padding-right: 1em;
}

// helper ---------------------------------------------------------------------
@include media-breakpoint-down(md) {
    .row-label {
        font-weight: bold;
    }
}

// link-list ------------------------------------------------------------------
.article-link > a {
    font-size: 20px;
}

// tag list -------------------------------------------------------------------
ul.tag-list {
    list-style: none;
    padding-left: 0 !important;
}

a.tag-link,
a.tag-link:hover,
a.tag-link:active,
a.tag-link:visited {
    display: flex;
    align-items: center;
    font-size: 18px;
    text-decoration: none;
}

a.tag-link i {
    font-size: 15px;
}

a.tag-link .tag-link-count {
    margin-left: 0.25em;
}

body.fsde-theme-light {
    .tag-link {
        color: $fsde-color-light-grey;
    }

    .tag-link span.tag-link-count {
        color: $fsde-color-light-black;
    }
}

body.fsde-theme-dark {
    .tag-link {
        color: $fsde-color-dark-grey;
    }

    .tag-link span.tag-link-count {
        color: $fsde-color-dark-black;
    }
}
