.fsde-pagination {
    display: flex;
    align-items: top;
    margin-bottom: $fsde-grid-gutter-half;
}

.fsde-pagination .fsde-pagination-left {
    flex-grow: 0;
    text-align: left;
    font-size: 80%;
}

.fsde-pagination .fsde-pagination-center {
    flex-grow: 1;
    text-align: center;
}

.fsde-pagination .fsde-pagination-right {
    flex-grow: 0;
    text-align: right;
    font-size: 80%;
}

.fsde-pagination .fsde-pagination-center ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.fsde-pagination .fsde-pagination-center ul li {
    display: inline;
}

.fsde-pagination .fsde-pagination-center ul li.active {
    padding: 0 0.2em;
    border-radius: 5px;
}

.fsde-pagination .fsde-pagination-center ul li {
    margin-right: 5px;
}

.fsde-pagination .fsde-pagination-center ul li:last-child {
    margin-right: 0;
}

.fsde-pagination .fsde-pagination-center ul li a {
    text-decoration: none;
}

// themes ---------------------------------------------------------------------
.fsde-theme-light {
    .fsde-pagination {
        color: $fsde-color-light-grey;
    }

    .fsde-pagination .fsde-pagination-center a {
        color: $fsde-color-light-black;
    }

    .fsde-pagination .fsde-pagination-center li.active {
        background-color: $fsde-color-light-black;
        color: $fsde-color-light-white;
    }
}

.fsde-theme-dark {
    .fsde-pagination {
        color: $fsde-color-dark-grey;
    }

    .fsde-pagination .fsde-pagination-center {
        color: $fsde-color-dark-black;
    }

    .fsde-pagination .fsde-pagination-center a {
        color: $fsde-color-dark-black;
    }
}