@use "sass:meta";

// speed highlight
.fsde-theme-light {
    @include meta.load-css("@speed-highlight/core/dist/themes/github-light.css");

    span.code {
        background-color: $fsde-color-light-light-grey;
    }
}

.fsde-theme-dark {
    @include meta.load-css("@speed-highlight/core/dist/themes/github-dim.css");

    span.code {
        background-color: $fsde-color-dark-light-grey;
    }
}

code.shj-inline {
    display: block !important;
    position: relative;
    width: 100%;
    margin-top: $fsde-grid-gutter-half !important;
    margin-bottom: $fsde-grid-gutter !important;
    font-family: $fsde-monospace-font-family !important;
    font-size: $fsde-monospace-font-size !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    overflow: auto;
}

// copy to clipboard
code.shj-inline .copy-to-clipboard {
    font-family: $fsde-font-family;
    position: absolute;
    top: 2px;
    right: 5px;
}

code.shj-inline .copy-to-clipboard::after {
    font-family: $fsde-icon-font-family;
    content: "\f0c5";
    padding-left: 0.5em;
    cursor: pointer;
}