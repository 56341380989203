$avatar-width: 50px;
$avatar-height: 50px;

@include media-breakpoint-up(md) {
    header .fsde-mobile-header {
        display: none !important;
    }
}

header .fsde-mobile-header .fsde-mobile-banner {
    display: flex;
    align-items: center;
}

header .fsde-mobile-header .fsde-mobile-banner .fsde-avatar img {
    width: $avatar-width;
    height: $avatar-height;
    border-radius: 9999px;
    margin-right: $fsde-grid-gutter-half;
}

header .fsde-mobile-header .fsde-mobile-banner-text {
    width: 100%;
}

header .fsde-mobile-header .fsde-mobile-banner-text a,
header .fsde-mobile-header .fsde-mobile-banner-text a:hover,
header .fsde-mobile-header .fsde-mobile-banner-text a:active,
header .fsde-mobile-header .fsde-mobile-banner-text a:visited {
    font-weight: 900;
    text-decoration: none;
}

header .fsde-mobile-header .fsde-mobile-banner-right i {
    font-size: 30px;
}

// navigation -----------------------------------------------------------------
header .fsde-mobile-header .fsde-mobile-banner .fsde-mobile-navigation-toggle {
    cursor: pointer;
}

header .fsde-mobile-header .fsde-mobile-menu {
    height: 0px;
    overflow: hidden;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body {
    padding-top: $fsde-grid-gutter;
    height: 100%;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav {
    padding-bottom: $fsde-grid-gutter-half;
    height: 100%;
}

// social media links
header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links {
    padding: 0;
    margin-bottom: $fsde-grid-gutter-half;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li {
    display: inline;
    list-style: none;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a {
    margin-right: 5px;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li.right {
    float: right;
    width: auto;
}

// navigation
header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation {
    list-style: none;
    margin: 0;
    padding: 0;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a {
    text-decoration: none;
    font-size: 30px;
}

header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active {
    font-weight: bolder;
}

// themes ---------------------------------------------------------------------
.fsde-theme-light {

    // banner text
    header .fsde-mobile-header .fsde-mobile-banner-text a,
    header .fsde-mobile-header .fsde-mobile-banner-text a:hover,
    header .fsde-mobile-header .fsde-mobile-banner-text a:active,
    header .fsde-mobile-header .fsde-mobile-banner-text a:visited {
        color: $fsde-color-light-black;
    }

    // menu
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body {
        color: $fsde-color-light-black;
    }

    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav {
        border-bottom: 2px solid $fsde-color-light-grey;
    }

    // social media links
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a:hover,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a:active,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a:visited {
        color: $fsde-color-light-black;
    }

    // navigation
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a:hover,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a:active,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a:visited {
        color: $fsde-color-light-grey;
    }

    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a:hover,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a:active,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a:visited {
        color: $fsde-color-light-black;
    }
}

.fsde-theme-dark {

    // banner text
    header .fsde-mobile-header .fsde-mobile-banner-text a,
    header .fsde-mobile-header .fsde-mobile-banner-text a:hover,
    header .fsde-mobile-header .fsde-mobile-banner-text a:active,
    header .fsde-mobile-header .fsde-mobile-banner-text a:visited {
        color: $fsde-color-dark-black;
    }

    // menu
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body {
        color: $fsde-color-dark-black;
    }

    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav {
        border-bottom: 2px solid $fsde-color-dark-grey;
    }

    // social media links
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a:hover,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a:active,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-social-media-links li a:visited {
        color: $fsde-color-dark-black;
    }

    // navigation
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a:hover,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a:active,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li a:visited {
        color: $fsde-color-dark-grey;
    }

    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a:hover,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a:active,
    header .fsde-mobile-header .fsde-mobile-menu .fsde-mobile-menu-body nav ul.fsde-navigation li.active a:visited {
        color: $fsde-color-dark-black;
    }
}
