// fonts
$fsde-font-family: 'Source Sans 3 VF';
$fsde-monospace-font-family: 'Source Code Variable';
$fsde-icon-font-family: 'Font Awesome 6 Free';
$fsde-font-size: 18px;
$fsde-monospace-font-size: 16px;

// grid
$fsde-page-width: 768px;
$fsde-grid-gutter: 30px;
$fsde-grid-gutter-half: 15px;
$fsde-grid-gutter-double: 60px;

// colors
$fsde-color-light-black: #30363C;
$fsde-color-light-white: #FAFAFA;
$fsde-color-light-grey: #8795A1;
$fsde-color-light-light-grey: #F2F2F2;

$fsde-color-dark-black: #FAFAFA;
$fsde-color-dark-white: #30363C;
$fsde-color-dark-grey: #8795A1;
$fsde-color-dark-light-grey: #22272E;
